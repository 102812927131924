(function ($) {
    "use strict";


    /*------ Sticky menu start ------*/
    var $window = $(window);
    $window.on('scroll', function () {
        var scroll = $window.scrollTop();
        if (scroll < 300) {
            $(".sticky").removeClass("is-sticky");
        } else {
            $(".sticky").addClass("is-sticky");
        }
    });
    /*------ Sticky menu end ------*/

    /*-------- Background Image JS start --------*/
    /*-------- Background Image JS end --------*/

    /*------- Image zoom effect start -------*/
    //$('.img-zoom').zoom();
    /*------- Image zoom effect end -------*/

    /*------- tooltip active js start -------*/
    $('[data-toggle="tooltip"]').tooltip();
    /*------- tooltip active js start -------*/


    /*------ Latest blog carousel active start ------*/
    /*
    $('.latest-blog-carousel').slick({
        autoplay: false,
        speed: 1000,
        slidesToShow: 3,
        adaptiveHeight: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="lnr lnr-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="lnr lnr-chevron-right"></i></button>',
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 2
            }
        },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }]
    });

     */
    /*------ Latest blog carousel active end ------*/

    /*------ Hero main slider active start ------*/
    /*
    $('.blog-carousel-active').slick({
        autoplay: true,
        fade: true,
        speed: 1000,
        dots: false,
        slidesToShow: 1,
        prevArrow: '<button type="button" class="slick-prev"><i class="lnr lnr-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="lnr lnr-chevron-right"></i></button>'
    });

     */
    /*------ Hero main slider active end ------*/

    /*------- offcanvas search form active start -------*/
    $(".offcanvas-btn").on('click', function () {
        $("body").addClass('fix');
        $(".offcanvas-search-inner").addClass('show')
    })


    /*
    $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on('click', function () {
        $("body").removeClass('fix');
        $(".offcanvas-search-inner, .minicart-inner").removeClass('show')
    });
    /*------- offcanvas search form active end -------*/


    /*------- nice select active start -------*/
    //$('select').niceSelect();
    /*------- nice select active end -------*/

    /*-------- Off Canvas Open close start--------*/
    $(".off-canvas-btn").on('click', function () {
        $("body").addClass('fix');
        $(".mobile-offcanvas").addClass('open');
    });

    $(".btn-close-off-canvas,.off-canvas-overlay").on('click', function () {
        $("body").removeClass('fix');
        $(".mobile-offcanvas").removeClass('open');
    });
    /*-------- Off Canvas Open close end--------*/

    // pricing filter

    var rangeSlider = $(".price-range"),
        amount = $("#amount"),
        minPrice = rangeSlider.data('min'),
        maxPrice = rangeSlider.data('max');
    rangeSlider.slider({
        range: true,
        min: 0,
        max: 1200,
        values: [100, 1000],
        slide: function (event, ui) {
            amount.val("$" + ui.values[0] + " - $" + ui.values[1]);
        }
    });

    amount.val(" $" + rangeSlider.slider("values", 0) +
        " - $" + rangeSlider.slider("values", 1));

    /*------- product view mode change js start -------*/

    /*------- product view mode change js end -------*/

    /*------- Countdown Activation start -------*/

    /*------- Countdown Activation end -------*/


    /*--------- quantity change js end ---------*/

    /*-------- scroll to top start --------*/
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 600) {
            $('.scroll-top').removeClass('not-visible');
        } else {
            $('.scroll-top').addClass('not-visible');
        }
    });
    $('.scroll-top').on('click', function (event) {
        $('html,body').animate({
            scrollTop: 0
        }, 1000);
    });
    /*-------- scroll to top end --------*/

    /*------- offcanvas mobile menu start -------*/
    var $offCanvasNav = $('.mobile-menu'),
        $offCanvasNavSubMenu = $offCanvasNav.find('.dropdown');

    /*Add Toggle Button With Off Canvas Sub Menu*/
    $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i></i></span>');

    /*Close Off Canvas Sub Menu*/
    $offCanvasNavSubMenu.slideUp();

    /*Category Sub Menu Toggle*/
    $offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
        var $this = $(this);
        if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
            e.preventDefault();
            if ($this.siblings('ul:visible').length) {
                $this.parent('li').removeClass('active');
                $this.siblings('ul').slideUp();
            } else {
                $this.parent('li').addClass('active');
                $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                $this.closest('li').siblings('li').find('ul:visible').slideUp();
                $this.siblings('ul').slideDown();
            }
        }
    });
    /*------- offcanvas mobile menu end -------*/

    /*------- Checkout Page accordion start -------*/
    $("#create_pwd").on("change", function () {
        $(".account-create").slideToggle("100");
    });

    $("#ship_to_different").on("change", function () {
        $(".ship-to-different").slideToggle("100");
    });

    // Payment Method Accordion
    $('input[name="paymentMethod"]').on('change', function () {
        var $value = $(this).attr('value');
        $('.payment-method-details').slideUp();
        $('[data-method="' + $value + '"]').slideDown();
    });
    /*------- Checkout Page accordion start -------*/

    // slide effect dropdown
    function dropdownAnimation() {
        $('.toggle-bar').on('show.bs.toggle-bar', function (e) {
            $(this).find('.dropdown-list').first().stop(true, true).slideDown(5000);
        });

        $('.toggle-bar').on('hide.bs.toggle-bar', function (e) {
            $(this).find('.dropdown-list').first().stop(true, true).slideUp(5000);
        });
    }

    dropdownAnimation();

}(jQuery));
