import Vue from 'vue';
import 'leaflet/dist/leaflet.css';
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: '/img/placeholder.svg',
    attempt: 1
});


const files = require.context('./', true, /\.vue$/i, 'lazy').keys();

files.forEach(file => {
    Vue.component(file.split('/').pop().split('.')[0], () => import(`${file}` /* webpackChunkName: "[request]"  */));
});
